import axios from "axios";
import {
  convertBase64,
  decrypt,
  decryptAES,
  encrypt,
  encryptAES,
  generateIV,
  generateKey,
  parseBase64,
} from "utils/encryption";

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export const eCommerceServiceInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/ecommerce-service`,
});

export const paymentServiceInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/payment-service`,
});

export const commonServiceInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/common-service/api/v1`,
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const orderCityPay = async (payload) => {
  const { data } = await paymentServiceInstance.post(
    `/api/external/citypay`,
    payload
  );
  return data;
};

export const orderCityPayStatus = async (params) => {
  const { data } = await paymentServiceInstance.get(
    `/api/external/citypay?` + params
  );
  return data;
};

export const bogTransaction = async (payload) => {
  const { data } = await paymentServiceInstance.post(
    "/api/external/bog/transaction/pay",
    payload
  );

  return data;
};
export const tbcTransaction = async (payload) => {
  const { data } = await paymentServiceInstance.post(
    "/api/external/tbc/transaction/pay",
    payload
  );

  return decrypt(data.encryptedData);
};

export const credoTransaction = async (payload) => {
  const { data } = await paymentServiceInstance.post(
    "/api/external/credo/transaction/pay",
    payload
  );

  return decrypt(data.encryptedData);
};

export const standardTransaction = async (payload) => {
  const {
    data: { encryptedData, encryptedKeys },
  } = await paymentServiceInstance.post(
    "/api/transaction/external/pay",
    payload
  );
  const decryptedKeys = decrypt(encryptedKeys, { shouldParse: false });
  const [base64SecretKey, base64IV] = decryptedKeys.split(".");
  const secretKey = parseBase64(base64SecretKey);
  const iv = parseBase64(base64IV);
  return decryptAES(encryptedData, secretKey, iv);
};

export const getSystemStatus = async () => {
  const resp = await axiosClient.get("/api/system/status");

  return resp;
};

export const getToken = async () => {
  const payload = {
    password: "someBank",
    username: "someBank",
  };

  const resp = await axiosClient.post(`/api/external/authenticate`, payload);
  return resp;
};

export const getUser = async (userId) => {
  const resp = axiosClient.get(`/api/user/info/id/${userId}`);

  return resp;
};

export const getSinglePayLimit = async () => {
  const { data } = await axiosClient.get(`/api/single-payment/limit`);

  return data;
};

export const makePayzeTransaction = async (payload) => {
  const { data } = await axiosClient.post(
    `/api/payze/transaction/pay`,
    payload
  );
  return data;
};

export const makeApplePayTransaction = async (payload) => {
  const { data } = await axiosClient.post(
    `/api/payze/transaction/pay`,
    payload
  );
  return data;
};

export const makeBogTransaction = async (payload) => {
  const { data } = await axiosClient.post(`/api/bog/transaction/pay`, payload);
  return data;
};

export const startApplePayment = async (payload) => {
  const { data } = await paymentServiceInstance.post(
    "/api/external/bog/transaction/pay/applePay",
    payload
  );
  return data;
};

export const acceptApplePay = async (payload, params) => {
  const { data } = await paymentServiceInstance.post(
    "/api/gc/transaction/accept",
    payload,
    {
      params,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }
  );
  return data;
};

export const acceptApplePayTbc = async (payload) => {
  const { data } = await paymentServiceInstance.post(
    "api/tbc-ma/transaction/apple/register",
    payload
  );
  return data;
};
export const acceptApplePayCredo = async (payload) => {
  const { data } = await paymentServiceInstance.post(
    "/api/ma/transaction/apple/register",
    payload
  );
  return data;
};

export const makeEmoneyTransaction = async (payload) => {
  const { data } = await axiosClient.post(
    `/api/card/transaction/make`,
    payload
  );
  return data;
};

export const getPaymentProvider = async () => {
  const { data } = await axiosClient.get("/api/paymentProvider");

  return data;
};

export const addReview = async (payload) => {
  const { data } = await paymentServiceInstance.post(
    "/api/v1/review/encrypted",
    payload
  );

  return decrypt(data.encryptedData);
};

export const sendPartnershipRequest = async (data) => {
  const res = paymentServiceInstance.post("/api/v1/info", data);
  return res;
};

export const postTransactionAction = async (integratorOrderId) => {
  try {
    const encryptedData = encrypt(
      { integratorOrderId },
      { shouldEncode: true }
    );
    const { data } = await eCommerceServiceInstance.get(
      `/api/integrator/order/post-transaction/external/check?identifier=${encodeURI(
        process.env.REACT_APP_INTEGRATOR_ID
      )}&encryptedData=${encryptedData}`
    );
    return decrypt(data.encryptedData);
  } catch (e) {
    console.error(e);
  }
};
export const checkOrderStatus = async (integratorOrderId, integratorId) => {
  const encryptedData = encrypt(
    { integratorOrderId, integratorId },
    { shouldEncode: true }
  );
  const { data } = await eCommerceServiceInstance.get(
    `/api/integrator/order/request-order-check/external?identifier=${encodeURI(
      process.env.REACT_APP_INTEGRATOR_ID
    )}&encryptedData=${encryptedData}`
  );

  return decrypt(data);
};

export const getPendingStatus = async (transactionId) => {
  const encryptedDataOne = encrypt(
    { id: transactionId },
    { shouldEncode: true }
  );
  const data = paymentServiceInstance.get(
    `/api/external/bog/transaction/check/appleGoogle?identifier=${encodeURI(
      process.env.REACT_APP_INTEGRATOR_ID
    )}&encryptedData=${encryptedDataOne}`
  );

  return data;
};

export const tipWithCard = async (
  transactionId,
  amount,
  percent,
  senderCommission
) => {
  const encryptedDataOne = encrypt({
    transactionId: transactionId,
    ...(amount && { amount: amount }),
    ...(percent && { percent: percent }),
    ...(senderCommission && { senderCommission: senderCommission }),
  });

  const data = paymentServiceInstance.post("api/tips/external/pay", {
    identifier: process.env.REACT_APP_INTEGRATOR_ID,
    encryptedData: encryptedDataOne,
  });

  return data;
};
export const tipWithCardTbc = async (
  transactionId,
  amount,
  percent,
  senderCommission
) => {
  const encryptedDataOne = encrypt({
    transactionId: transactionId,
    ...(amount && { amount: amount }),
    ...(percent && { percent: percent }),
    ...(senderCommission && { senderCommission: senderCommission }),
  });

  const data = paymentServiceInstance.post("api/tips/external/pay/tbc", {
    identifier: process.env.REACT_APP_INTEGRATOR_ID,
    encryptedData: encryptedDataOne,
  });

  return data;
};
export const tipWithCardCredo = async (
  transactionId,
  amount,
  percent,
  senderCommission
) => {
  const encryptedDataOne = encrypt({
    transactionId: transactionId,
    ...(amount && { amount: amount }),
    ...(percent && { percent: percent }),
    ...(senderCommission && { senderCommission: senderCommission }),
  });

  const { data } = await paymentServiceInstance.post(
    "api/tips/external/pay/credo",
    {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: encryptedDataOne,
    }
  );

  return data;
};

export const tipCard = async (payload) => {
  const {
    data: { encryptedData, encryptedKeys },
  } = await paymentServiceInstance.post("/api/tips/external/pay", payload);
  const decryptedKeys = decrypt(encryptedKeys, { shouldParse: false });
  const [base64SecretKey, base64IV] = decryptedKeys.split(".");
  const secretKey = parseBase64(base64SecretKey);
  const iv = parseBase64(base64IV);
  return decryptAES(encryptedData, secretKey, iv);
};

export const tipWithGooglePay = async (
  transactionId,
  amount,
  percent,
  senderCommission,
  convertedAmount,
  currency
) => {
  const encryptedDataOne = encrypt({
    transactionId: transactionId,
    ...(amount && { amount: amount }),
    ...(percent && { percent: percent }),
    ...(senderCommission && { senderCommission: senderCommission }),
    ...(currency == "EUR" && { convertedAmount: convertedAmount }),
  });

  const { data } = await paymentServiceInstance.post(
    "api/tips/external/pay/googlePay",
    {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: encryptedDataOne,
    }
  );

  return data;
};
export const tipWithGooglePayTbc = async (
  transactionId,
  amount,
  percent,
  senderCommission,
  convertedAmount,
  currency
) => {
  const encryptedDataOne = encrypt({
    transactionId: transactionId,
    ...(amount && { amount: amount }),
    ...(percent && { percent: percent }),
    ...(senderCommission && { senderCommission: senderCommission }),
    ...(currency == "EUR" && { convertedAmount: convertedAmount }),
  });

  const { data } = await paymentServiceInstance.post(
    "api/tips/external/pay/tbc/google-pay",
    {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: encryptedDataOne,
    }
  );

  return data;
};
export const tipWithGooglePayCredo = async (
  transactionId,
  amount,
  percent,
  senderCommission,
  convertedAmount,
  currency
) => {
  const encryptedDataOne = encrypt({
    transactionId: transactionId,
    ...(amount && { amount: amount }),
    ...(percent && { percent: percent }),
    ...(senderCommission && { senderCommission: senderCommission }),
    ...(currency == "EUR" && { convertedAmount: convertedAmount }),
  });

  const { data } = await paymentServiceInstance.post(
    "api/tips/external/pay/credo/google-pay",
    {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: encryptedDataOne,
    }
  );

  return data;
};

export const tipGoogle = async (
  transactionId,
  amount,
  percent,
  senderCommission,
  convertedAmount,
  currency,
  acquiringType
) => {
  const IV = generateIV();
  const secretkey = generateKey();
  const ivBase64 = convertBase64(IV);
  const secretKeyBase64 = convertBase64(secretkey);
  const base64 = `${secretKeyBase64}.${ivBase64}`;
  const payload = {
    id: transactionId,
    ...(amount && { amount: amount }),
    ...(percent && { percent: percent }),
    ...(senderCommission && { senderCommission: senderCommission }),
    ...((currency == "EUR" || currency == "USD") && {
      convertedAmount: convertedAmount,
    }),
    acquiringType: acquiringType,
  };
  const {
    data: { encryptedData, encryptedKeys },
  } = await paymentServiceInstance.post("/api/tips/external/pay/google", {
    identifier: process.env.REACT_APP_INTEGRATOR_ID,
    encryptedData: encryptAES(payload, secretkey, IV),
    encryptedKeys: encrypt(base64, { stringify: false }),
  });
  const decryptedKeys = decrypt(encryptedKeys, { shouldParse: false });
  const [base64SecretKey, base64IV] = decryptedKeys.split(".");
  const secretKey = parseBase64(base64SecretKey);
  const iv = parseBase64(base64IV);
  return decryptAES(encryptedData, secretKey, iv);
};

export const tipWithApplePay = async (
  transactionId,
  amount,
  percent,
  senderCommission,
  convertedAmount,
  currency
) => {
  const encryptedDataOne = encrypt({
    id: transactionId,
    ...(amount && { amount: amount }),
    ...(percent && { percent: percent }),
    ...(senderCommission && { senderCommission: senderCommission }),
    ...(currency == "EUR" && { convertedAmount: convertedAmount }),
  });

  const { data } = await paymentServiceInstance.post(
    `api/tips/external/pay/applePay`,
    {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: encryptedDataOne,
    }
  );

  return data;
};
export const tipWithApplePayTbc = async (
  transactionId,
  amount,
  percent,
  senderCommission,
  convertedAmount,
  currency
) => {
  const encryptedDataOne = encrypt({
    transactionId: transactionId,
    ...(amount && { amount: amount }),
    ...(percent && { percent: percent }),
    ...(senderCommission && { senderCommission: senderCommission }),
    ...(currency == "EUR" && { convertedAmount: convertedAmount }),
  });

  const { data } = await paymentServiceInstance.post(
    `/api/tips/external/pay/tbc/apple-pay`,
    {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: encryptedDataOne,
    }
  );

  return data;
};
export const tipWithApplePayCredo = async (
  transactionId,
  amount,
  percent,
  senderCommission,
  convertedAmount,
  currency
) => {
  const encryptedDataOne = encrypt({
    transactionId: transactionId,
    ...(amount && { amount: amount }),
    ...(percent && { percent: percent }),
    ...(senderCommission && { senderCommission: senderCommission }),
    ...(currency == "EUR" && { convertedAmount: convertedAmount }),
  });

  const { data } = await paymentServiceInstance.post(
    `/api/tips/external/pay/credo/apple-pay`,
    {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: encryptedDataOne,
    }
  );

  return data;
};

export const tipApple = async (
  transactionId,
  amount,
  percent,
  senderCommission,
  convertedAmount,
  currency,
  acquiringType
) => {
  const IV = generateIV();
  const secretkey = generateKey();
  const ivBase64 = convertBase64(IV);
  const secretKeyBase64 = convertBase64(secretkey);
  const base64 = `${secretKeyBase64}.${ivBase64}`;
  const payload = {
    id: transactionId,
    ...(amount && { amount: amount }),
    ...(percent && { percent: percent }),
    ...(senderCommission && { senderCommission: senderCommission }),
    ...((currency == "EUR" || currency == "USD") && {
      convertedAmount: convertedAmount,
    }),
    acquiringType: acquiringType,
  };
  const {
    data: { encryptedData, encryptedKeys },
  } = await paymentServiceInstance.post("/api/tips/external/pay/apple", {
    identifier: process.env.REACT_APP_INTEGRATOR_ID,
    encryptedData: encryptAES(payload, secretkey, IV),
    encryptedKeys: encrypt(base64, { stringify: false }),
  });
  const decryptedKeys = decrypt(encryptedKeys, { shouldParse: false });
  const [base64SecretKey, base64IV] = decryptedKeys.split(".");
  const secretKey = parseBase64(base64SecretKey);
  const iv = parseBase64(base64IV);
  return decryptAES(encryptedData, secretKey, iv);
};

export const getTransactionDetails = async (transactionId) => {
  const encryptedDataOne = encrypt(
    {
      id: transactionId,
    },
    { shouldEncode: true }
  );

  const { data } = await paymentServiceInstance.get(
    `api/external/bog/transaction/details?identifier=${encodeURI(
      process.env.REACT_APP_INTEGRATOR_ID
    )}&encryptedData=${encryptedDataOne}`
  );

  return decrypt(data.encryptedData);
};
export const getTransactionDetailsTbc = async (transactionId) => {
  const encryptedDataOne = encrypt(
    {
      id: transactionId,
    },
    { shouldEncode: true }
  );

  const { data } = await paymentServiceInstance.get(
    `api/tbc/transaction/external/details?identifier=${encodeURI(
      process.env.REACT_APP_INTEGRATOR_ID
    )}&encryptedData=${encryptedDataOne}`
  );

  return decrypt(data.encryptedData);
};

export const getTransactionDetailsCredo = async (transactionId) => {
  const encryptedDataOne = encrypt(
    {
      id: transactionId,
    },
    { shouldEncode: true }
  );

  const { data } = await paymentServiceInstance.get(
    `api/credo/transaction/external/details?identifier=${encodeURI(
      process.env.REACT_APP_INTEGRATOR_ID
    )}&encryptedData=${encryptedDataOne}`
  );

  return decrypt(data.encryptedData);
};

export const getTransDetails = async (data, secretkeys) => {
  const {
    data: { encryptedData, encryptedKeys },
  } = await paymentServiceInstance.get(
    `/api/transaction/external/invoice?identifier=${process.env.REACT_APP_INTEGRATOR_ID}&encryptedData=${data}&encryptedKeys=${secretkeys}`
  );
  const decryptedKeys = decrypt(encryptedKeys, { shouldParse: false });
  const [base64SecretKey, base64IV] = decryptedKeys.split(".");
  const secretKey = parseBase64(base64SecretKey);
  const iv = parseBase64(base64IV);
  return decryptAES(encryptedData, secretKey, iv);
};

export const getThreeDsStatus = async (token) => {
  const encryptedData = encrypt(
    {
      token: token,
    },
    { shouldEncode: true }
  );

  const { data } = await paymentServiceInstance.get(
    `api/external/bog/transaction/history/google?identifier=${encodeURI(
      process.env.REACT_APP_INTEGRATOR_ID
    )}&encryptedData=${encryptedData}`
  );

  return decrypt(data.encryptedData);
};

export const startUfcApplePayment = async (payload) => {
  const encryptedDataOne = encrypt(payload);

  const { data } = await paymentServiceInstance.post(
    "api/external/ma/transaction/pay/applePay",
    {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: encryptedDataOne,
    }
  );

  return decrypt(data.encryptedData);
};
export const startTbcApplePayment = async (payload) => {
  const encryptedDataOne = encrypt(payload);

  const { data } = await paymentServiceInstance.post(
    "api/external/tbc-ma/transaction/pay/applePay",
    {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: encryptedDataOne,
    }
  );

  return decrypt(data.encryptedData);
};

export const startUfcGooglePayment = async (payload) => {
  const encryptedDataOne = encrypt(payload);

  const { data } = await paymentServiceInstance.post(
    "api/external/ma/transaction/pay/googlePay",
    {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: encryptedDataOne,
    }
  );

  return decrypt(data.encryptedData);
};
export const startTbcGooglePayment = async (payload) => {
  const encryptedDataOne = encrypt(payload);

  const { data } = await paymentServiceInstance.post(
    "api/external/tbc-ma/transaction/pay/googlePay",
    {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: encryptedDataOne,
    }
  );

  return decrypt(data.encryptedData);
};

export const acceptUfcGooglePayPayment = async (payload, params) => {
  const { data } = await paymentServiceInstance.post(
    `api/ma/transaction/google/register/${encodeURI(params)}`,
    payload
  );
  return data;
};

export const acceptTbcGooglePayPayment = async (payload, params) => {
  const { data } = await paymentServiceInstance.post(
    `api/tbc-ma/transaction/google/register/${encodeURI(params)}`,
    payload
  );
  return data;
};

export const getCommisionForTips = async (userId, userType, transactionId) => {
  const encryptedData = encrypt(
    {
      userId: userId,
      userType: userType,
      transactionId: transactionId,
    },
    { shouldEncode: true }
  );
  const { data } = await paymentServiceInstance.get(
    `api/tips/external/config?identifier=${encodeURI(
      process.env.REACT_APP_INTEGRATOR_ID
    )}&encryptedData=${encryptedData}`
  );

  return decrypt(data.encryptedData);
};

export const oBRequest = async (payload) => {
  const encryptedDataOne = encrypt(payload);

  const { data } = await paymentServiceInstance.post(
    "api/v1/open-banking/transaction/external/pay",
    {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: encryptedDataOne,
    }
  );

  return data;
};

export const obStatusCheck = async (payload) => {
  const encryptedDataOne = encrypt(payload);
  const { data } = await paymentServiceInstance.post(
    "api/v1/open-banking/transaction/external/finalise",
    {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: encryptedDataOne,
    }
  );

  return decrypt(data.encryptedData);
};

export const tipOBRequest = async (
  transactionId,
  amount,
  percent,
  senderCommission,
  openBankingProviderId
) => {
  const encryptedDataOne = encrypt({
    transactionId: transactionId,
    ...(amount && { amount: amount }),
    ...(percent && { percent: percent }),
    ...(senderCommission && { senderCommission: senderCommission }),
    openBankingProviderId: openBankingProviderId,
  });
  const { data } = await paymentServiceInstance.post(
    "api/tips/external/pay/ob",
    {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: encryptedDataOne,
    }
  );

  return data;
};

export const obStatusCheckRepeat = async (id) => {
  const encryptedDataOne = encrypt({
    id: id,
  });
  const { data } = await paymentServiceInstance.post(
    "/api/v1/open-banking/transaction/external/status",
    {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: encryptedDataOne,
    }
  );

  return decrypt(data.encryptedData);
};

export const getTransactionDetailsOB = async (transactionId) => {
  const encryptedDataOne = encrypt(
    {
      id: transactionId,
    },
    { shouldEncode: true }
  );

  const { data } = await paymentServiceInstance.get(
    `api/v1/open-banking/transaction/external/details?identifier=${encodeURI(
      process.env.REACT_APP_INTEGRATOR_ID
    )}&encryptedData=${encryptedDataOne}`
  );

  return decrypt(data.encryptedData);
};

export const getOBProviders = async () => {
  const { data } = await paymentServiceInstance(
    `api/v1/open-banking/provider/external/get-providers?identifier=${encodeURI(
      process.env.REACT_APP_INTEGRATOR_ID
    )}`
  );

  return decrypt(data.encryptedData);
};
