import {
  ADD_AVAILABLE_PAYMENT_OPTION,
  SET_AMOUNT,
  SET_APPLE_PAY_STATE,
  SET_GPAY_STATE,
  SET_INPUT_ERROR,
  SET_LOADING_PAYMENT,
  SET_PAYMENT_RESULT,
  SET_PAYMENT_TYPE,
  SET_TOGGLE_PAYMENT_MODAL,
} from "./actions";

function paymentReducer(state, { type, payload }) {
  switch (type) {
    case SET_GPAY_STATE:
      return {
        ...state,
        gpayState: payload,
      };
    case SET_APPLE_PAY_STATE:
      return {
        ...state,
        applePayState: payload,
      };
    case SET_LOADING_PAYMENT:
      return {
        ...state,
        loadingPayment: payload,
      };
    case SET_PAYMENT_RESULT:
      return {
        ...state,
        paymentResult: payload,
      };
    case SET_AMOUNT:
      return {
        ...state,
        amount: payload,
      };
    case SET_INPUT_ERROR:
      return {
        ...state,
        inputError: payload,
      };
    case SET_TOGGLE_PAYMENT_MODAL:
      return {
        ...state,
        showPaymentModal: !state.showPaymentModal,
      };
    case ADD_AVAILABLE_PAYMENT_OPTION:
      return {
        ...state,
        availablePaymentOptions: [...state.availablePaymentOptions, payload],
      };
    case SET_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: payload,
      };
    default:
      return state;
  }
}

export default paymentReducer;
