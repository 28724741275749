import { paymentServiceInstance } from "api";

export const startGooglePayPayment = async (payload) => {
  const { data } = await paymentServiceInstance.post(
    `/api/external/bog/transaction/pay/googlePay`,
    payload
  );
  return data;
};

export const startGooglePayPayment1 = async (payload) => {
  const { data } = await paymentServiceInstance.post(
    `/api/external/bog/transaction/pay/googlePay1`,
    payload
  );
  return data;
};

export const acceptGooglePayPayment = async (payload, params) => {
  const { data } = await paymentServiceInstance.post(
    `/api/gc/transaction/accept/googlePay`,
    payload,
    {
      params,
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return data;
};
